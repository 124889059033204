import React, { useState, useEffect, ChangeEvent } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllDocuments } from '../../../../slices/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { selectUser } from '../../../../slices/user/userSlice';
import { selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { selectAllDocumentContracts } from '../../../../slices/document-contracts/documentContractsSlice';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { TAGS_LABELS } from '../../../../common/constants/tags-labels';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const DocumentLabelsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const [documentTypeLabel, setDocumentTypeLabel] = useState([]);
  const [documentWorkflowLabel, setDocumentWorkflowLabel] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { documentId } = useParams();
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(documentId));
  const documentContract = useAppSelector(selectAllDocumentContracts).find(
    (el) => el.id === Number(documentId)
  );
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectUser);
  const company = useAppSelector(selectAllCompanies)[0];
  const documentTypeLabels = TAGS_LABELS.documentType.map((el) => t(el));
  const getTypeLabels = () => {
    return documentTypeLabels.map((el) => ({ title: el, value: el }));
  };

  const documentWorkflowLabelsEmployment = TAGS_LABELS.documentWorkflow.employment.map((el) =>
    t(el)
  );
  const documentWorkflowLabelsEmploymentSorted = documentWorkflowLabelsEmployment.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsSupplier = TAGS_LABELS.documentWorkflow.supplier.map((el) => t(el));
  const documentWorkflowLabelsSupplierSorted = documentWorkflowLabelsSupplier.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsCustomer = TAGS_LABELS.documentWorkflow.customer.map((el) => t(el));
  const documentWorkflowLabelsCustomerSorted = documentWorkflowLabelsCustomer.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsCompany = TAGS_LABELS.documentWorkflow.company.map((el) => t(el));
  const documentWorkflowLabelsCompanySorted = documentWorkflowLabelsCompany.sort((a, b) =>
    a.localeCompare(b)
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
  const checkedIcon = <CheckBoxIcon fontSize='medium' />;

  const getWorkflowLabels = () => {
    const docItem = document || documentContract;
    switch (docItem?.category) {
      case 'employment':
        return documentWorkflowLabelsEmploymentSorted.map((el) => ({ title: el, value: el }));
      case 'suppliers':
        return documentWorkflowLabelsSupplierSorted.map((el) => ({ title: el, value: el }));
      case 'company':
        return documentWorkflowLabelsCompanySorted.map((el) => ({ title: el, value: el }));
      case 'customers':
        return documentWorkflowLabelsCustomerSorted.map((el) => ({ title: el, value: el }));
      default:
        return [];
    }
  };

  const updateDocumentItemLabels = () => {
    setLoading(true);
    NWClient.patch(
      token,
      document ? 'tag-document-upload' : 'tag-document-contract',
      document ? document.id : documentContract.id,
      {
        [document ? 'du_types' : 'dc_types']: documentTypeLabel.map((el) => el.value),
        [document ? 'du_workflows' : 'dc_workflows']: documentWorkflowLabel.map((el) => el.value),
      }
    )
      .then(() => {
        toast.success(t('messages.documentUpdated'), { theme: 'colored' });
      })
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (document) {
      setDocumentTypeLabel(document.du_types.map((el) => ({ title: el, value: el })));
      setDocumentWorkflowLabel(document.du_workflows.map((el) => ({ title: el, value: el })));
    } else if (documentContract) {
      setDocumentTypeLabel(documentContract.dc_types.map((el) => ({ title: el, value: el })));
      setDocumentWorkflowLabel(
        documentContract.dc_workflows.map((el) => ({ title: el, value: el }))
      );
    }
  }, [document, documentContract]);

  return (
    <>
      <TabPanel value={currentTab} index={3} prefix={'vertical'}>
        <>
          <div className='p-4'>
            <h2 className='mb-4'>{t('documentTabs.labels')}</h2>
          </div>
          <div className='d-md-flex d-lg-flex flex-md-wrap flex-lg-wrap'>
            <div className='w-50'>
              <div className='d-flex p-4 pt-0'>
                <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.category')}:</div>
                <div className='col-9 text-capitalize'>
                  {document?.category || documentContract?.category}
                </div>
              </div>
              <div className='d-flex p-4 pt-0'>
                <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.company')}:</div>
                <div className='col-9'>{company?.name}</div>
              </div>
              <div className='d-flex p-4 pt-0'>
                <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.name')}:</div>
                <div className='col-9'>
                  {currentUser.first_name
                    ? currentUser.first_name + ' ' + currentUser.last_name
                    : currentUser.last_name}
                </div>
              </div>
              <div className='d-flex p-4 pt-0'>
                <div className='col-3 pe-3 text-capitalize fw-bold'>{t('labels.email')}:</div>
                <div className='col-9'>{currentUser.email}</div>
              </div>
            </div>
            <div className='w-50'>
              <div className='d-flex p-4 pt-0'>
                <div className='col-3 pe-3 fw-bold'>
                  <label htmlFor='documentTypeSelect'>{t('labels.documentType')}:</label>
                </div>
                <div className='col-9'>
                  <Autocomplete
                    className='mt-0 w-100'
                    multiple
                    id='documentWTypeSelect'
                    openOnFocus={true}
                    options={getTypeLabels()}
                    disableCloseOnSelect={true}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    getOptionLabel={(option) => option.title}
                    value={documentTypeLabel}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      newValue:
                        | {
                            title: string;
                            value: string;
                          }[]
                        | null
                    ) => {
                      setDocumentTypeLabel(newValue);
                    }}
                    renderOption={(props, option, { selected }) => {
                      const { ...optionProps } = props;
                      return (
                        <li key={option.value} {...optionProps}>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} variant='standard' />}
                  />
                </div>
              </div>
              <div className='d-flex p-4 pt-0'>
                <div className='col-3 pe-3 fw-bold'>
                  <label htmlFor='documentWorkflowSelect'>{t('labels.documentWorkflow')}:</label>
                </div>
                <div className='col-9'>
                  <Autocomplete
                    className='mt-0 w-100'
                    multiple
                    id='documentWorkflowSelect'
                    openOnFocus={true}
                    options={getWorkflowLabels()}
                    disableCloseOnSelect={true}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    getOptionLabel={(option) => option.title}
                    value={documentWorkflowLabel}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                      newValue:
                        | {
                            title: string;
                            value: string;
                          }[]
                        | null
                    ) => {
                      setDocumentWorkflowLabel(newValue);
                    }}
                    renderOption={(props, option, { selected }) => {
                      const { ...optionProps } = props;
                      return (
                        <li key={option.value} {...optionProps}>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} variant='standard' />}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex w-100 justify-content-end p-4'>
              <Button variant='contained' onClick={() => updateDocumentItemLabels()}>
                {t('buttons.save')}
              </Button>
            </div>
          </div>
        </>
      </TabPanel>
      {loading && <ProgressOverlay />}
    </>
  );
};
