import React from 'react';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';

import { StyledTableCell } from './components/TableCell';
import { StyledTableRow } from './components/TableRow';
import { TableHeader } from './components/TableHeader';
import { Template } from '../../../../models/template.model';
import { ActionsCell } from './components/ActionsCell';
import { HTMLTemplateModel } from '../../../../models/html.template.model';
import { DocumentTemplateModel } from '../../../../models/document.template.model';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../common/constants/routes';
import { Helpers } from '../../../../common/helpers/helpers';

export type TemplatesTableProps = {
  templates: Template[] | HTMLTemplateModel[] | DocumentTemplateModel[];
};

export const TemplatesTable = ({ templates }: TemplatesTableProps) => {
  const { name } = useParams();
  const navigate = useNavigate();
  return templates.length > 0 ? (
    <TableContainer
      elevation={0}
      sx={{ py: 2, px: 3, borderRadius: 5 }}
      variant='outlined'
      component={Paper}
    >
      <Table aria-label='templates table'>
        <TableHeader />
        <TableBody sx={{ px: 2 }}>
          {templates.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell
                component='th'
                scope='row'
                onClick={() =>
                  ('document_upload' in row || name === 'uk' || name === 'au') &&
                  navigate(`${ROUTES.DOCUMENT_LIBRARY}/${name}/${row.id}?templateViewEditMode=true`)
                }
              >
                <div className='templates-table-name'>{row.name}</div>
              </StyledTableCell>
              {name === 'company-templates' && (
                <StyledTableCell>
                  <div className='date-time'>
                    {'updated' in row && Helpers.timeStampToDate(row.updated)}
                  </div>
                </StyledTableCell>
              )}
              <ActionsCell align='right' row={row} />
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};
