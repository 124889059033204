import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { Helpers } from '../../../../common/helpers/helpers';
import { useTranslation } from 'react-i18next';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import GenerateTemplateIcon from '../../../../../static/assets/svg/generate-template-icon.svg';

interface SettingsMenuProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const SettingsMenu = ({ value, handleChange }: SettingsMenuProps) => {
  const { t } = useTranslation();
  return (
    <div className='settings-menu position-sticky'>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='navigation'
          orientation='vertical'
          role='navigation'
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          tabIndex={0}
        >
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <SummarizeOutlinedIcon />
              </div>
            }
            label={t('documentTabs.summary')}
            {...Helpers.a11yProps(0, 'vertical')}
          />
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <TocOutlinedIcon />
              </div>
            }
            label={t('documentTabs.content')}
            {...Helpers.a11yProps(1, 'vertical')}
          />
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <PublishedWithChangesOutlinedIcon />
              </div>
            }
            label={t('documentTabs.versions')}
            {...Helpers.a11yProps(2, 'vertical')}
          />
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <LinkOutlinedIcon />
              </div>
            }
            label={t('documentTabs.labels')}
            {...Helpers.a11yProps(3, 'vertical')}
          />
          <Tab
            disableFocusRipple
            disableRipple
            iconPosition='start'
            className='settings-menu-tab'
            icon={
              <div className={'settings-menu-icon-container'}>
                <GenerateTemplateIcon />
              </div>
            }
            label={t('documentTabs.template')}
            {...Helpers.a11yProps(4, 'vertical')}
          />
        </Tabs>
      </div>
    </div>
  );
};
