import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import Brand from '../../../static/assets/svg/brand.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  fetchDocuments,
  selectAllDocuments,
  uploadDocument,
} from '../../slices/documents/documentsSlice';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { Box, Paper, Select, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { selectUser } from '../../slices/user/userSlice';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledTableRow } from '../category/components/TemplatesTable/components/TableRow';
import { StyledTableCell } from '../category/components/TemplatesTable/components/TableCell';
import { ActionsCell } from './components/ActionsCell';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { NWClient } from '../../client/NWClient';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../slices/document-contracts/documentContractsSlice';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { Helpers } from '../../common/helpers/helpers';
import { AIDocumentContractModelWithId } from '../../models/ai.document.contract.model';
import InfoLogo from '../../../static/assets/svg/disclaimer-info.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { TAGS_LABELS } from '../../common/constants/tags-labels';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PopoverWithButton from '../../common/popover-with-button/PopoverWithButton';

const DocumentsCategoryPage = () => {
  const token = localStorage.getItem('access_token');
  const { category } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const documents = useAppSelector(selectAllDocuments);
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const currentUser = useAppSelector(selectUser);
  const [documentTypeLabel, setDocumentTypeLabel] = useState<string>('');
  const [documentWorkflowLabel, setDocumentWorkflowLabel] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [uploadControlOpened, setUploadControlOpened] = useState<boolean>(true);
  const [templates, setTemplates] = useState<DocumentTemplateModel[]>([]);
  const [sorting, setSorting] = useState<'asc' | 'desc'>('asc');
  const [sortingBy, setSortingBy] = useState<'date' | 'name' | 'fileType'>('date');
  const documentTypeLabels = TAGS_LABELS.documentType.map((el) => t(el));
  const documentTypeLabelsSorted = documentTypeLabels.sort((a, b) => a.localeCompare(b));
  const documentWorkflowLabelsEmployment = TAGS_LABELS.documentWorkflow.employment.map((el) =>
    t(el)
  );
  const documentWorkflowLabelsEmploymentSorted = documentWorkflowLabelsEmployment.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsSupplier = TAGS_LABELS.documentWorkflow.supplier.map((el) => t(el));
  const documentWorkflowLabelsSupplierSorted = documentWorkflowLabelsSupplier.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsCustomer = TAGS_LABELS.documentWorkflow.customer.map((el) => t(el));
  const documentWorkflowLabelsCustomerSorted = documentWorkflowLabelsCustomer.sort((a, b) =>
    a.localeCompare(b)
  );
  const documentWorkflowLabelsCompany = TAGS_LABELS.documentWorkflow.company.map((el) => t(el));
  const documentWorkflowLabelsCompanySorted = documentWorkflowLabelsCompany.sort((a, b) =>
    a.localeCompare(b)
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
  const checkedIcon = <CheckBoxIcon fontSize='medium' />;

  const getWorkflowLabels = () => {
    switch (category) {
      case 'employment':
        return documentWorkflowLabelsEmploymentSorted.map((el) => ({ title: el, value: el }));
      case 'suppliers':
        return documentWorkflowLabelsSupplierSorted.map((el) => ({ title: el, value: el }));
      case 'company':
        return documentWorkflowLabelsCompanySorted.map((el) => ({ title: el, value: el }));
      case 'customers':
        return documentWorkflowLabelsCustomerSorted.map((el) => ({ title: el, value: el }));
      default:
        return [];
    }
  };

  const getFileTypeFromName = (row: { name?: string; contract_generation?: number }) => {
    const lastCharacters = row.name?.slice(-4);
    if (row.contract_generation === undefined) {
      if (lastCharacters.includes('.')) {
        const charactersWithoutDot = lastCharacters.replace('.', '');
        return charactersWithoutDot;
      }
      return lastCharacters;
    } else {
      return 'bk';
    }
  };
  const documentsFiltered =
    category === 'templates'
      ? templates
      : documents.filter((el) => el.category === category && el.user === currentUser.id);
  const documentContractsFiltered = documentContracts.filter(
    (el) => el.category === category && el.user === currentUser.id
  );
  const itemsComposed: (
    | DocumentTemplateModel
    | AIDocumentContractModelWithId
    | DocumentVaultItemModel
  )[] = [...documentsFiltered, ...documentContractsFiltered].map((el) => {
    return { ...el, fileType: getFileTypeFromName(el) };
  });
  const filtering = (el: any) => {
    if (documentTypeLabel && documentWorkflowLabel.length === 0) {
      if ('du_types' in el) {
        return el.du_types.includes(documentTypeLabel);
      } else if ('dc_types' in el) {
        return el.dc_types.includes(documentTypeLabel);
      }
    } else if (!documentTypeLabel && documentWorkflowLabel.length > 0) {
      if ('du_workflows' in el) {
        return documentWorkflowLabel.every((item) => el.du_workflows.includes(item.value));
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabel.every((item) => el.dc_workflows.includes(item.value));
      }
    } else if (documentTypeLabel && documentWorkflowLabel.length > 0) {
      if ('du_types' in el && 'du_workflows' in el) {
        return (
          el.du_types.includes(documentTypeLabel) &&
          documentWorkflowLabel.every((item) => el.du_workflows.includes(item.value))
        );
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return (
          el.dc_types.includes(documentTypeLabel) &&
          documentWorkflowLabel.every((item) => el.dc_workflows.includes(item.value))
        );
      }
    }
  };
  const sortByDate = (a: any, b: any) => {
    return (
      Number(new Date((sorting === 'asc' ? b : a).updated)) -
      Number(new Date((sorting === 'asc' ? a : b).updated))
    );
  };
  const sortByName = (a: any, b: any) => {
    return (sorting === 'asc' ? a : b).name.localeCompare((sorting === 'asc' ? b : a).name);
  };
  const sortByFileType = (a: any, b: any) => {
    return (sorting === 'asc' ? a : b).fileType.localeCompare((sorting === 'asc' ? b : a).fileType);
  };

  const itemsSortedByDate =
    documentTypeLabel || documentWorkflowLabel.length > 0
      ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByDate(a, b))
      : [...itemsComposed].sort((a, b) => sortByDate(a, b));
  const itemsSortedByName =
    documentTypeLabel || documentWorkflowLabel.length > 0
      ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByName(a, b))
      : [...itemsComposed].sort((a, b) => sortByName(a, b));
  const itemsSortedByFileType =
    documentTypeLabel || documentWorkflowLabel.length > 0
      ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByFileType(a, b))
      : [...itemsComposed].sort((a, b) => sortByFileType(a, b));

  const handleSorting = (sortingValue: 'name' | 'fileType' | 'date') => {
    if (sortingValue !== sortingBy) {
      setSortingBy(sortingValue);
      setSorting('asc');
    } else {
      if (sorting === 'asc') {
        setSorting('desc');
      } else {
        setSorting('asc');
      }
    }
  };

  const getItemsArray = () => {
    switch (sortingBy) {
      case 'name':
        return itemsSortedByName;
      case 'date':
        return itemsSortedByDate;
      case 'fileType':
        return itemsSortedByFileType;
    }
  };

  const uploadDocumentFn = () => {
    setPendingStatus(true);
    const dataTransfer = new DataTransfer();
    uploadedFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });
    formRef.current.querySelector('[type="file"]').files = dataTransfer.files;
    // Help Safari out
    if (formRef.current.querySelector('[type="file"]').webkitEntries.length) {
      formRef.current.querySelector('[type="file"]').dataset.file = `${dataTransfer.files[0].name}`;
    }
    const data = new FormData(formRef.current);
    data.set('user', String(currentUser.id));
    data.set('category', category);
    dispatch(uploadDocument({ token, data }))
      .unwrap()
      .then((res: DocumentVaultItemModel) => {
        setUploadedFiles([]);
        if (formRef && formRef.current) {
          formRef.current.reset();
        }
        NWClient.post(token, 'document-upload-html', { document_upload: res.id })
          .then(() => {
            toast.success(t('messages.documentUploaded'), { theme: 'colored' });
          })
          .catch((error) => {
            toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
          })
          .finally(() => {
            setPendingStatus(false);
          });
      })
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        setPendingStatus(false);
      });
  };
  const setUploadButtonText = () => {
    if ((!uploadControlOpened && documentsFiltered.length > 0) || documentsFiltered.length === 0) {
      return t('buttons.upload');
    } else if (uploadControlOpened && documentsFiltered.length > 0) {
      return t('buttons.save');
    }
  };
  const handleUploadButtonClick = () => {
    if (uploadControlOpened || documentsFiltered.length === 0) {
      uploadDocumentFn();
    } else {
      setUploadControlOpened(true);
    }
  };

  const handleNavigateByRowClick = (row: any) => {
    navigate(
      category === 'company-templates'
        ? `${pathName}/${row.id}?initialTab=1`
        : `${pathName}/${row.id}${
            row.contract_generation ? '?initialTab=1&contractGenerated=true' : ''
          }`
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchDocuments({ token, data: { user: currentUser.id, category } }))
      .unwrap()
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
    dispatch(fetchDocumentContracts({ token, data: { user: currentUser.id, category } }))
      .unwrap()
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
        <h1 className='text-capitalize text-center mb-3'>{category}</h1>
      </div>
      {loading ? (
        <div className='text-center'>
          <ProgressOverlay className='position-relative' />
        </div>
      ) : (
        <>
          {category !== 'templates' && (
            <>
              <Dropzone
                accept={{
                  'application/msword': ['.doc'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                    '.docx',
                  ],
                  'application/pdf': ['.pdf'],
                }}
                onDrop={(acceptedFiles) => {
                  setUploadedFiles(acceptedFiles);
                }}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <form ref={formRef}>
                    <div className='dropzone-wrapper'>
                      <div {...getRootProps()}>
                        <input
                          name='document_file'
                          ref={inputRef}
                          type='file'
                          title='Upload a file'
                          {...getInputProps()}
                          multiple={false}
                        />
                        <UploadFileIcon fontSize='large' />
                        <p className='dropzone-message'>
                          <span className='link-style'>
                            {uploadedFiles.length > 0 ? '' : t('messages.clickToUpload') + '.'}
                          </span>
                        </p>
                      </div>
                      {uploadedFiles.length > 0 && (
                        <div className='success'>
                          {/*t('labels.uploadedFile')*/}
                          {uploadedFiles.map((el) => (
                            <span key={el.name}>{el.name}</span>
                          ))}
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </Dropzone>

              <div className='buttons-row d-flex justify-content-end align-items-center mb-3 mt-3'>
                {uploadedFiles.length > 0 && (
                  <Button
                    role='button'
                    type='button'
                    onClick={() => {
                      setUploadedFiles([]);
                      formRef.current.reset();
                    }}
                  >
                    {t('buttons.reset')}
                  </Button>
                )}
                <Button
                  style={{ marginLeft: '0.5rem' }}
                  role='button'
                  disabled={
                    documentsFiltered.length === 0
                      ? uploadedFiles.length === 0
                      : uploadedFiles.length === 0 && uploadControlOpened
                  }
                  variant='contained'
                  onClick={() => handleUploadButtonClick()}
                >
                  {setUploadButtonText()}
                </Button>
              </div>
            </>
          )}

          {documentsFiltered.length > 0 || documentContractsFiltered.length > 0 ? (
            <>
              {(documentsFiltered.length > 1 || documentContractsFiltered.length > 1) && (
                <div className='d-lg-flex justify-content-lg-between align-items-lg-end mt-5 mb-3 table-filtering-block'>
                  <div className='d-lg-flex col-lg-6 mb-2'>
                    <div className='w-100 flex-lg-grow-1 me-2 mb-2'>
                      <label htmlFor='documentTypeSelect'>{t('labels.documentType')}</label>
                      <div>
                        <Select
                          className='w-100'
                          id='documentTypeSelect'
                          value={documentTypeLabel}
                          onChange={(e) => setDocumentTypeLabel(e.target.value)}
                        >
                          {documentTypeLabelsSorted.map((el) => (
                            <MenuItem key={el} value={el}>
                              {el}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className='w-100 flex-lg-grow-1 mb-2'>
                      <label htmlFor='documentWorkflowSelect'>{t('labels.documentWorkflow')}</label>
                      <div>
                        <Autocomplete
                          className='mt-0 w-100'
                          multiple
                          id='documentWorkflowSelect'
                          openOnFocus={true}
                          options={getWorkflowLabels()}
                          disableCloseOnSelect={true}
                          isOptionEqualToValue={(option, value) => option.title === value.title}
                          getOptionLabel={(option) => option.title}
                          value={documentWorkflowLabel}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            newValue:
                              | {
                                  title: string;
                                  value: string;
                                }[]
                              | null
                          ) => {
                            setDocumentWorkflowLabel(newValue);
                          }}
                          renderOption={(props, option, { selected }) => {
                            const { ...optionProps } = props;
                            return (
                              <li key={option.value} {...optionProps}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField {...params} variant='standard' />}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mb-2'>
                    <Button
                      className='mb-2'
                      disabled={!documentTypeLabel && documentWorkflowLabel.length === 0}
                      variant='contained'
                      size='medium'
                      onClick={() => {
                        setDocumentTypeLabel('');
                        setDocumentWorkflowLabel([]);
                      }}
                    >
                      {t('buttons.resetFilters')}
                    </Button>
                  </div>
                </div>
              )}
              <TableContainer
                elevation={0}
                sx={{ py: 2, px: 3, borderRadius: 5 }}
                variant='outlined'
                component={Paper}
                style={{ marginBottom: '1.5rem' }}
              >
                <Table aria-label='templates table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingBy === 'name'}
                          direction={sortingBy === 'name' ? sorting : 'asc'}
                          onClick={() => handleSorting('name')}
                        >
                          {t('templatesTable.nameColumnLabel')}
                          {sortingBy === 'name' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingBy === 'fileType'}
                          direction={sortingBy === 'fileType' ? sorting : 'asc'}
                          onClick={() => handleSorting('fileType')}
                          style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                        >
                          {t('labels.fileType')}
                          {sortingBy === 'fileType' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingBy === 'date'}
                          direction={sortingBy === 'date' ? sorting : 'asc'}
                          onClick={() => handleSorting('date')}
                          style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                        >
                          {t('labels.date')}
                          {sortingBy === 'date' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                        {t('labels.labels')}
                      </StyledTableCell>
                      <StyledTableCell align='right'>
                        {t('templatesTable.actionColumnLabel')}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ px: 2 }}>
                    {getItemsArray().map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          onClick={() => handleNavigateByRowClick(row)}
                        >
                          <div className='templates-table-name'>{row.name}</div>
                        </StyledTableCell>
                        <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                          {row.fileType === 'bk' ? (
                            <Tooltip title={t('labels.bkGeneratedDocument')} placement='top' arrow>
                              <div className={'file-type-label ' + row.fileType}>
                                <Brand />
                              </div>
                            </Tooltip>
                          ) : (
                            <div className={'file-type-label ' + row.fileType}>{row.fileType}</div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                          <div className='date-time'>{Helpers.timeStampToDate(row.updated)}</div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {('du_types' in row && row.du_types.length > 0) ||
                          ('du_workflows' in row && row.du_workflows.length > 0) ||
                          ('dc_types' in row && row.dc_types.length > 0) ||
                          ('dc_workflows' in row && row.dc_workflows.length > 0) ? (
                            <PopoverWithButton
                              id={String(row.id)}
                              buttonText={String(
                                ('du_types' in row ? row.du_types.length : 0) +
                                  ('du_workflows' in row ? row.du_workflows.length : 0) +
                                  ('dc_types' in row ? row.dc_types.length : 0) +
                                  ('dc_workflows' in row ? row.dc_workflows.length : 0)
                              )}
                            >
                              <Stack
                                direction='row'
                                sx={{
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  gap: '0.375rem',
                                  maxWidth: '200px',
                                  padding: '0.5rem',
                                }}
                              >
                                {'du_types' in row
                                  ? row.du_types.map((el) => (
                                      <Chip key={el} label={el} color='secondary' />
                                    ))
                                  : null}
                                {'du_workflows' in row
                                  ? row.du_workflows.map((el) => (
                                      <Chip key={el} label={el} color='primary' />
                                    ))
                                  : null}
                                {'dc_types' in row
                                  ? row.dc_types.map((el) => (
                                      <Chip key={el} label={el} color='secondary' />
                                    ))
                                  : null}
                                {'dc_workflows' in row
                                  ? row.dc_workflows.map((el) => (
                                      <Chip key={el} label={el} color='primary' />
                                    ))
                                  : null}
                              </Stack>
                            </PopoverWithButton>
                          ) : null}
                        </StyledTableCell>
                        <ActionsCell align='right' row={row} />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
        </>
      )}
      {pendingStatus ? <ProgressOverlay /> : ''}
    </div>
  );
};

export default DocumentsCategoryPage;
