import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { StyledTableCell } from '../TableCell';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../../../common/constants/routes';

export const TableHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { name, documentId } = useParams();
  const path = location.pathname;
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>{t('templatesTable.nameColumnLabel')}</StyledTableCell>
        {path.includes(ROUTES.DOCUMENT_VAULT) && !documentId ? (
          <>
            <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
              {t('labels.fileType')}
            </StyledTableCell>
            <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
              {t('labels.date')}
            </StyledTableCell>
            <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
              {t('labels.labels')}
            </StyledTableCell>
          </>
        ) : null}
        {name === 'company-templates' && (
          <StyledTableCell style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
            {t('labels.date')}
          </StyledTableCell>
        )}
        <StyledTableCell align='right'>{t('templatesTable.actionColumnLabel')}</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
