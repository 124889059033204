import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import TemplateDashboardSearchBar from '../templates/TemplateDashboardSearchBar';
import { useCategory } from './hooks/useCategory';
import { TemplatesTable } from './components/TemplatesTable';
import { Link } from 'react-router-dom';
import CategoriesPage from '../categories/CategoriesPage';
import SVG from 'react-inlinesvg';
import { getResourceLink, getSvgIconColorByCategory } from '../../common/utils';
import { useParams, useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { HTMLTemplateModel } from '../../models/html.template.model';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ROUTES } from '../../common/constants/routes';
import DialogItem from '../../common/dialog-item/DialogItem';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import Tooltip from '@mui/material/Tooltip';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import InfoLogo from '../../../static/assets/svg/disclaimer-info.svg';
import {
  fetchDocumentTemplates,
  selectAllDocumentTemplates,
} from '../../slices/document-templates/documentTemplatesSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { AIDocumentContractModelWithId } from '../../models/ai.document.contract.model';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';

export const CategoryPage = () => {
  const token = localStorage.getItem('access_token');
  const { searchCategories, category } = useCategory();
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const documents = useAppSelector(selectAllDocuments).filter((el) => el.user === currentUser.id);
  const templates = useAppSelector(selectAllDocumentTemplates);
  const navigate = useNavigate();
  const { name } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [scroll, setScroll] = useState<'body' | 'paper'>('paper');
  const [createTemplateDialogVisible, setCreateTemplateDialogVisible] = useState<boolean>(false);
  const [regionTemplates, setRegionTemplates] = useState<HTMLTemplateModel[]>([]);
  const [sorting, setSorting] = useState<string>('asc');
  const [sortingBy, setSortingBy] = useState<'date' | 'name'>('date');

  const handleTermsClose = () => {
    setOpenTerms(false);
  };

  const disclaimerKeys = [
    'beforeProceeding',
    'description',
    'tosDocAccess',
    'resourceOffering',
    'documentAccessibility',
    'docAccuracy',
    'disclaimers',
    'legalities',
    'redistribution',
    'intellectualProperty',
    'contactInfo',
  ];

  const itemsComposed = [...templates].map((item) => {
    return { ...item, name: documents.find((el) => el.id === item.document_upload)?.name };
  });

  const itemsSortedByDate = [...itemsComposed].sort(function (a, b) {
    return (
      Number(new Date((sorting === 'asc' ? b : a).updated)) -
      Number(new Date((sorting === 'asc' ? a : b).updated))
    );
  });

  const itemsSortedByName = [...itemsComposed].sort((a, b) =>
    (sorting === 'asc' ? a : b).name?.localeCompare((sorting === 'asc' ? b : a).name)
  );

  const getItemsArray = () => {
    switch (sortingBy) {
      case 'name':
        return itemsSortedByName;
      case 'date':
        return itemsSortedByDate;
    }
  };

  const handleSorting = (sortingValue: 'name' | 'date') => {
    if (sortingValue !== sortingBy) {
      setSortingBy(sortingValue);
      setSorting('asc');
    } else {
      if (sorting === 'asc') {
        setSorting('desc');
      } else {
        setSorting('asc');
      }
    }
  };

  const getSpecificHeaders = () => {
    switch (name) {
      case 'uk':
        return t('countries.uk');
      case 'au':
        return t('countries.au');
      case 'company-templates':
        return t('companyTemplatesHeader');
      default:
        return name;
    }
  };

  useEffect(() => {
    if (name === 'uk' || name === 'au') {
      setLoading(true);
      NWClient.list(token, 'document-library', { region: name }).then(
        (res: HTMLTemplateModel[]) => {
          setLoading(false);
          const templatesSortedByName = [...res].sort((a, b) => a.name.localeCompare(b.name));
          setRegionTemplates(templatesSortedByName);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (name === 'company-templates') {
      setLoading(true);
      if (documents.length === 0) {
        dispatch(fetchDocuments({ token, data: { user: currentUser.id } }));
      }
      dispatch(fetchDocumentTemplates({ token, data: { user: currentUser.id } }))
        .unwrap()
        .catch(() => {
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (category && category.sub_categories.length > 0) {
    return <CategoriesPage />;
  }

  if (!category && name !== 'uk' && name !== 'au' && name !== 'company-templates') {
    return (
      <Grid container justifyContent='center'>
        <ProgressOverlay className='position-relative' />
        {/*<CircularProgress/>*/}
      </Grid>
    );
  }

  return (
    <>
      <Grid container flexDirection='column' spacing={4} alignItems='center'>
        <Grid
          wrap={'nowrap'}
          container
          xs={12}
          sm={12}
          item
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <SVG
              color={getSvgIconColorByCategory(category?.parentName)}
              preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              description='category icon'
              className='category-icon'
              src={getResourceLink(category?.icon)}
            />
          </Grid>
          <Grid item className='w-100 d-flex justify-content-space-between align-items-start'>
            <Typography
              className={
                'category-name w-100 d-flex align-items-center justify-content-center position-relative'
              }
              component={'h1'}
              gutterBottom={false}
            >
              {category ? category.name : getSpecificHeaders()}
              <Button
                onClick={() => setOpenTerms(true)}
                style={{ position: 'absolute', right: '2rem' }}
              >
                <InfoLogo />
              </Button>
            </Typography>
            <Dialog
              open={openTerms}
              onClose={handleTermsClose}
              scroll={scroll}
              aria-labelledby='scroll-dialog-title'
              aria-describedby='scroll-dialog-description'
            >
              <DialogTitle id='scroll-dialog-title'>Document Terms</DialogTitle>
              <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                  id='scroll-dialog-description'
                  style={{ whiteSpace: 'pre-line' }}
                >
                  <b>{t('disclaimerText.beforeProceeding')}</b>
                  {t('disclaimerText.description')}
                  <Link to='https://businesskitz.com.au/terms-and-conditions/' target='_blank'>
                    Terms and Conditions
                  </Link>
                  {' and Business Kitz '}
                  <Link to='https://businesskitz.com.au/privacy-policy/' target='_blank'>
                    Privacy Policy
                  </Link>
                  {'.\n\n' +
                    disclaimerKeys
                      .slice(2)
                      .map((key) => t(`disclaimerText.${key}`))
                      .join('') +
                    '\n\n'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleTermsClose}>Go back</Button>
              </DialogActions>
            </Dialog>
            {name === 'company-templates' && templates.length > 0 && (
              <Tooltip title={t('buttons.createTemplate')} placement='top' arrow>
                <IconButton
                  style={{ marginLeft: '0.5rem', flexShrink: 0 }}
                  onClick={() => navigate(ROUTES.DOCUMENT_VAULT)}
                >
                  <SVG
                    src={'/assets/svg/generate-template-icon.svg'}
                    style={{ width: 30, height: 30, stroke: '#6414DB', fill: '#6414DB' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        {name !== 'uk' && name !== 'au' && name !== 'company-templates' && (
          <Grid container item justifyContent='center'>
            <TemplateDashboardSearchBar
              filtered
              categories={searchCategories}
              placeholder={'Enter a template name here, choose it from the list below if found'}
            />
          </Grid>
        )}
        {name === 'company-templates' && templates.length === 0 && (
          <Grid container item justifyContent='center'>
            <Grid item xs={12}>
              <p className='text-center mb-4'>{t('messages.templatesFromVault')}</p>
              <div className='text-center'>
                <IconButton
                  style={{ borderRadius: 30, padding: 20 }}
                  onClick={() => setCreateTemplateDialogVisible(true)}
                >
                  <SVG
                    src={'/assets/svg/generate-template-icon.svg'}
                    style={{ width: 30, height: 30, stroke: '#6414DB', fill: '#6414DB' }}
                  />
                  <span style={{ marginLeft: 10 }}>{t('buttons.createTemplate')}</span>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        )}
        {name === 'company-templates' && getItemsArray().length > 1 && (
          <div className='mt-5  d-flex w-100 justify-content-end'>
            <Button
              style={{ marginLeft: '0.5rem' }}
              variant='contained'
              onClick={() => handleSorting('date')}
            >
              <span className='me-1'>{t('buttons.sortByDate')}</span>
              {sortingBy === 'date' ? (
                sorting === 'asc' ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )
              ) : null}
            </Button>
            <Button
              style={{ marginLeft: '0.5rem' }}
              variant='contained'
              onClick={() => handleSorting('name')}
            >
              <span className='me-1'>{t('buttons.sortByName')}</span>
              {sortingBy === 'name' ? (
                sorting === 'asc' ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )
              ) : null}
            </Button>
          </div>
        )}
        <Grid container item justifyContent='center'>
          <TemplatesTable
            templates={
              category
                ? category.templates
                : name === 'company-templates'
                ? getItemsArray()
                : regionTemplates
            }
          />
        </Grid>
      </Grid>
      {createTemplateDialogVisible && (
        <DialogItem
          isErrorMessage={false}
          open={createTemplateDialogVisible}
          title={t('dialogTitles.createTemplate')}
          text={
            documents.length > 0
              ? t('messages.templateFromVaultWithDocs')
              : t('messages.templateFromVaultEmpty')
          }
          noDefaultActionsRow={true}
          handleClose={() => {
            setCreateTemplateDialogVisible(false);
          }}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              type='button'
              role='button'
              variant='outlined'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                setCreateTemplateDialogVisible(false);
              }}
            >
              {t('buttons.goBack')}
            </Button>
            <Button
              onClick={() => navigate(ROUTES.DOCUMENT_VAULT)}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='contained'
              size='medium'
            >
              {t('buttons.openVault')}
            </Button>
          </div>
        </DialogItem>
      )}
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};
