import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import { useTranslation } from 'react-i18next';
import { DocumentHtmlModel } from '../../../../models/document.html.model';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../../../client/NWClient';
import { useParams, useSearchParams } from 'react-router-dom';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { TableHeader } from '../../../category/components/TemplatesTable/components/TableHeader';
import { StyledTableRow } from '../../../category/components/TemplatesTable/components/TableRow';
import { StyledTableCell } from '../../../category/components/TemplatesTable/components/TableCell';
import { ActionsCell } from '../../../document-vault/components/ActionsCell';

export const DocumentVersionsTab = ({ currentTab, documentName }: TabProps) => {
  const { t } = useTranslation();
  const { documentId } = useParams();
  const [params] = useSearchParams();
  const contractGenerated = params.get('contractGenerated');
  const token = localStorage.getItem('access_token');
  const [versions, setVersions] = useState<DocumentHtmlModel[]>([]);
  const [htmlContentDialogVisible, setHTMLContentDialogVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingVersions, setLoadingVersions] = useState<boolean>(false);
  const [versionIndex, setVersionIndex] = useState<number>(1);

  useEffect(() => {
    if (!contractGenerated) {
      setLoadingVersions(true);
      NWClient.list(token, 'document-upload-html')
        .then((res: DocumentHtmlModel[]) => {
          setVersions(
            [...res]
              .filter((el) => el.document_upload === Number(documentId))
              .sort()
              .reverse()
          );
        })
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => setLoadingVersions(false));
    }
  }, []);

  return (
    <>
      <TabPanel value={currentTab} index={2} prefix={'vertical'}>
        <>
          <div className='p-4 position-relative' style={{ minHeight: 300 }}>
            <h2 className='mb-4'>{t('documentTabs.versions')}</h2>
            <TableContainer
              elevation={0}
              sx={{ py: 2, px: 3, borderRadius: 5 }}
              variant='outlined'
              component={Paper}
              className='mb-3'
            >
              <Table aria-label='templates table'>
                <TableHeader />
                <TableBody sx={{ px: 2 }}>
                  <StyledTableRow>
                    <StyledTableCell component='th' scope='row'>
                      <div className='templates-table-name'>{documentName}</div>
                    </StyledTableCell>
                    <ActionsCell
                      align='right'
                      row={{ id: Number(documentId), name: documentName }}
                    />
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {versions.map((el, i) => (
              <div key={el.updated} className='version-data mb-3 d-flex p-3'>
                <div className='document-data-container'>
                  <div className='data-row d-flex'>
                    <div className='label'>{t('labels.version')}:</div>
                    <div className='value-item'>{versions.length - i}</div>
                  </div>
                  <div className='data-row d-flex'>
                    <div className='label'>{t('labels.fileType')}:</div>
                    <div className='value-item'>HTML</div>
                  </div>
                  <div className='data-row d-flex'>
                    <div className='label'>{t('labels.changeDate')}:</div>
                    <div className='value-item'>{new Date(el.updated).toLocaleString()}</div>
                  </div>
                </div>
                <div className='changes-container ps-3 pe-3 flex-grow-1'>
                  <Button
                    type='button'
                    role='button'
                    variant='contained'
                    size='medium'
                    onClick={() => {
                      setVersionIndex(i + 1);
                      setHTMLContentDialogVisible(true);
                    }}
                  >
                    {t('buttons.viewVersionContent')}
                  </Button>
                </div>
              </div>
            ))}
            {loadingVersions ? <ProgressOverlay className='position-absolute m-0' /> : null}
          </div>
        </>
      </TabPanel>
      {htmlContentDialogVisible && (
        <DialogItem
          isErrorMessage={false}
          fullScreen={true}
          open={htmlContentDialogVisible}
          title={t('labels.version') + ' ' + versionIndex}
          handleClose={() => {
            setHTMLContentDialogVisible(false);
          }}
        >
          <div className='d-flex mb-3'>
            <div>{parse(versions[versionIndex - 1].html)}</div>
          </div>
        </DialogItem>
      )}
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};
